.boldText {
  font-weight: 500;
}

#dueMoney {
  border: none;
  height: 2px;
  background-color: black;
  margin: 10px auto;
}
.transactionHeading {
  color: #642315e5;
  font-weight: 500;
  font-size: 18px;
  border-bottom: 1.5px solid #642315e5;
  padding: 0.5rem;
}
.subadminlastbutton {
  margin-top: 1rem;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  margin-right: 0.1rem;
}

#downloadBtn {
  background-color: #e4cdaa;
  color: #642315;
  border: none;
  border-radius: 0.2rem;
  padding: 0;
  width: 10rem;
  padding-top: 0.4rem;
  padding-bottom: 0.3rem;
  border: 1px solid #642315;
}

.value {
  text-align: end;
}
