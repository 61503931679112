.productmodalheader{
    background-color: #F4F3F0;
    padding: 0;
    margin-top: 0.5rem;
    padding-right: 1rem;
    border-radius: 10px;
    padding-left: 0.6rem;
}
.modal-content{
    padding: 1rem;
}

.categoryinput{
    width: 100%;
    border: none;
    border-bottom: 1px solid;
}
.categoryinput:focus {
    outline: none;
  }
.addproductlastbutton{
    display: flex;
    justify-content: right;
}
.modalproductlastrow{
    width: 150px;
    height: 150px;
    border: 1px dashed #ccc;
    background-color: #f6f6f6;
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.categoryImage{
    height: 145px;
    width: 145px;
    padding: 5px;
}
.plus{
    margin-left: 51px;
    margin-top: 54px;
}
.inputBox{
    height: 200px;
    width: 200px;
    display: none;
}
.categoryImg{
    height: 137px;
    width: 150px;
    padding: 2px;
    margin-left: -12px;
    margin-bottom: -5px;
}
