.cardslice {
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  background-color: #fefefe;
}
.dashcaard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 6px 0px;
  border-radius: 10px;
}
.dashcaard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 6px 0px;
  border-radius: 10px;
}
#dropdown-basic {
  background-color: unset;
  border: none;
}
.cardselect {
  width: fit-content;
  background-color: #f4f3f0;
  border: none;
  outline: none;
}
h5 {
  color: #642315;
  font-family: "Roboto";
  font-size: larger;
}
h6 {
  color: #525252;
}
.yoyo {
  font-size: larger;
  font-weight: 500;
}
.flexendof {
  display: flex;
  justify-content: flex-end;
}

#cardid {
  color: #642315;
}
.lastcolomnofpennding {
  display: flex;
  justify-content: flex-end;
}
.OrdertNameHeading {
  color: #251c1a;
}
