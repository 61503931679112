.rr1 {
  background: #ffffff;
}
.rr2 {
  background: #f4f3f0;
  padding: 0;
}
.rr3 {
  height: 100vh;
}
.rr4 {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  /* padding: 1rem; */
  scrollbar-width: thin;
}
