.titleofcontainerrrr {
  margin-top: 0.2rem;
}

.search-wrapper.searchWrapper {
  border-radius: 6px;
  background-color: #f4f3f0;
  border: 1px solid #595959;
}

.productlastcol {
  border: dotted 1px solid;
}

.productlastcol {
  width: 150px;
  height: 150px;
  border: 1px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 5px;
  margin: 10px 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.productlastcol:hover {
  background-color: #e0e0e0;
}

#transaction-crossicon {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .productlastcol {
    /* width: 80%; */
    margin-left: 0;
    margin-top: 1rem;
  }
}

.productlastcol img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.productlastcol:hover:before {
  color: #333;
}
.subadminlastbutton {
  margin-top: 1rem;
}
.prodoductfoamrow {
  margin-top: 0.7rem;
}

.amthirdcoltype {
  padding: 0.5rem;
  color: black;
  font-weight: normal;
  font-family: "Roboto";
  background-color: #f4f3f0;
  border: 1px solid #595959;
}
.amthirdcollll {
  padding: 0.5rem;
  color: black;
  font-weight: normal;
  font-family: "Roboto";
  background-color: #f4f3f0;
  border: 1px solid 
    #595959;
}
.pattern{
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 50px;
  transition: 0.4s all;
}
.add-button {
  cursor: pointer;
  color: #642315;
  font-size: 25px;
}

.add-btn {
  transform: scale(0.8);
  margin-left: 0.5rem;
}
.color-box {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.color-box-container {
  border: 2px solid black;
  border-radius: 50%;
  padding: 2px;
  position: relative;
}
.cross-mark {
  position: absolute;
  top: -8px;
  right: -5px;
  font-size: 12px;
  cursor: pointer;
}
.loader{
 margin-top: 10px;
 margin-right: 5px;
 height: 25px;
 width: 25px;
}
.uploadLoader{
  margin:35px;
  height: 40px;
  width: 40px;
}
.imageInPattern{
  height: 50px;
  width: 50px;
  border-radius: 20px;
  margin: 10px;
  border: 2px solid rgb(13, 10, 10);
  padding: 3px;
}
.cross-mark-pattern{
position: absolute; 
right: 2px;
top: 2px;
font-size: 15px;
  cursor: pointer;
}
.cross-mark-picture{
  position: absolute;
  top: -45px;
  right: -25px;
  font-size: 20px;
  cursor: pointer;
}

.image-container {
  width: 80%;
  padding-top: 100%; /* Creates a 1:1 aspect ratio */
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  height: 60%;
}

.image-wrapper, .upload-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.cross-mark-picture {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 2px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.upload-label {
  cursor: pointer;
  background-color: #f8f9fa;
}

.upload-label svg {
  font-size: 24px;
  color: #6c757d;
}


.itemnameadmin {
  background-color: rgb(228, 205, 170);
  color: rgb(100, 35, 21);
  font-weight: 500;
  padding: 4px;
  border-radius: 8px;
  letter-spacing: 1px;
  margin: 0;
}

.deleteiconitem {
  color: rgb(100, 35, 21);
  /* size: 31rem; */
  font-size: 1.5rem;
}

.color-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dimension-item {
  display: flex;
  align-items: center;
  background-color: rgb(228, 205, 170);
  border-radius: 8px;
  padding: 4px 8px;
  margin-bottom: 4px;
}

.dimension-item p {
  margin: 0;
  margin-right: 8px;
  color: rgb(100, 35, 21);
  font-weight: 500;
  word-break: break-word;
}

.dimension-item .RxCross2 {
  cursor: pointer;
  color: rgb(100, 35, 21);
  font-size: 1.2rem;
}
.color-container {
  max-width: 100%;
}

.dimension-item {
  max-width: calc(100% - 16px); 
}
.DimentionSizeText{
  display: flex;
  align-items: center;
}