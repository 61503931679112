.tnb1 {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background: #ffffff;
  color: #000000;
}
.tnb2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  margin-left: auto;
}
.tnb3 {
  height: 1.5rem;
  width: 1.5rem;
  color: #414141;
  
  cursor: pointer;
}
.tnb4 {
  cursor: pointer;
  font-weight: 500;
  margin-right: 0;
}
.tnb5 {
  position: relative;
  cursor: pointer;
  margin-right: 1.5rem;
}
.tnb6 {
  color: red;
  position: absolute;
  top: 0;
  left: 0.7em;
}
.tnb7 {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  cursor: pointer;
}
.tnb1 img {
  height: 2rem;
  width: 2rem;
  margin-top: 0.5rem;
}
@media only screen and (max-width: 600px) {
  .tnb1 {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: #ffffff;
    color: #000000;
  }
  .tnb2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }
  .tnb7 {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;
  }
}
/* .container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
