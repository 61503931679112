.salerymaincontainer {
  background-color: #fefefe;
  padding: 1.3rem;
  margin-top: 1rem;
  border-radius: 14px;
  font-family: "Roboto";
}
.amtopbarsalery {
  background-color: #f4f3f0;
  font-family: "Roboto";
  padding: 0.5rem;
  font-size: large;
  color: #642315e5;
  margin-top: 3vh;
  border-radius: 9px;
  margin-top: 1vh;
}

.straightline {
  width: 100%;
  height: 1px;
  background-color: #642315;
}
.salerytablefirst {
  display: flex;
  justify-content: space-between;
}
.saleryrow {
  margin-top: 1rem;
}

tr:hover {
  background-color: #f5f5f5;
}

.action {
  text-align: center;
}

.action button {
  background-color: #f2f2f2;
  border: none;
  color: #333;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
}
.textalignment {
  text-align: right;
  font-family: "Roboto";
  margin-top: 0.2rem;
  font-weight: 500;
}
.texttopcontainer {
  font-family: "Roboto";
  margin-top: 0.2rem;
  font-weight: 500;
}
.salerytable {
  width: 100%;
  border: 1px solid #595959;
  background-color: #f4f3f0;
  border-radius: 5px;
  border-collapse: separate;
  font-family: "Roboto";
  font-weight: 400;
}

td {
  border-bottom: 1px solid #555555;
}
th {
  border-bottom: 1px solid #642315;
}

.lasttr {
  margin-bottom: 1rem;
}

.colnameuser {
  font-family: "Roboto";
  font-weight: 500;
}

#managepass {
  display: flex;
  justify-content: right;
}
.managepassword {
  color: #642315;
  border: 1px solid #595959;
  font-weight: 500;
  background-color: #e4cdaa;
  font-family: "Roboto", sans-serif;
  width: fit-content;
  font-style: normal;
  margin-left: 1rem;
  padding: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 7px;
}

.attendance-photo {
  width: 4vw;
  height: 9vh;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .attendance-photo {
    width: 15vw;
    height: auto;
  }
}

@media (max-width: 480px) {
  .attendance-photo {
    width: 20vw;
    height: auto;
  }
}
#text-color {
  color: black !important;
}
