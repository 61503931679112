.value{
    text-align: end;
}

.transactionHeading{
    color: #642315E5;
    font-weight: 500;
    border-bottom: 1.5px solid #642315E5;
    padding: 0.5rem;
}
#btnAndIcon button{
    border:1px solid black;
    color: black;
    background-color: #F4F3F0;
    border-radius: 0.2rem;
    font-weight: 500;
}
@media only screen and (max-width: 600px) {
    #btnAndIcon button{
        border:1px solid black;
        color: black;
        background-color: #F4F3F0;
        border-radius: 0.2rem;
        font-weight: 500;
        font-size: 13px;
    }   
}
