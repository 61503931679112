.productfirstcol {
  display: flex;
  gap: 1rem;
}

.producttoprow {
  background-color: #f4f3f0;
  display: flex;
  align-items: center;
  padding: 0.3rem;
  border-radius: 5px;
}
.producth5 {
  margin: 0;
  font-size: 1rem;
  background-color: #e4cdaa;
  padding: 0.2rem;
  border-radius: 9px;
}
.producth6 {
  margin: 0;
  margin-top: 3px;
  color: black;
  font-family: "Roboto";
}
.plusbutton {
  display: flex;
  justify-content: right;
  cursor: pointer;
}
.privacysecondcol {
  display: flex;
  justify-content: right;
}

.productimage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productimagefont {
  font-family: "Roboto";
  font-weight: 600;
  text-align: center;
}

.productmaincontainer {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  height: 234px;
  width: 182px;
  transform: scale(0.8);
}

.productimage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productimage img {
  max-width: 100%;
  height: 200px;
  object-fit: contain;
}

.productimagefont {
  text-align: center;
  font-weight: bold;
}
.maptoprow {
  margin-top: 1.5rem;
  display: grid;
  place-items: center;
}
@media (max-width: 767px) {
  /* Styles for mobile screens */

  .spantagproduct {
    position: absolute;
    top: -4px;
    right: 3px;
    cursor: pointer;
  }
}

@media (min-width: 360px) {
  /* Styles for mobile screens */
  .maptoprow {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
@media (min-width: 500px) {
  .maptoprow {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .maptoprow {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
  }
}

.productimage img {
  width: 100%;
  height: 200px; /* Set the desired fixed height */
  object-fit: contain; /* This property ensures the image maintains its aspect ratio while filling the specified dimensions */
}
