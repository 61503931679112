#QRContainer{
    height: 400px;
    width: 400px;
}
#qrNote{
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media only screen and (max-width: 600px) {
    #QRContainer{
        height: 200px;
        width: 200px;
    }
    #qrNote{
        text-align: center;
        font-weight: 400;
        font-size: 11px;
    }
}