.othercontainer {
  background-color: #fefefe;
  padding: 2rem;
}
button#uncontrolled-tab-example-tab-home {
  font-weight: 500;
}
button#uncontrolled-tab-example-tab-home:hover {
  color: #642315;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black;
  background-color: #c4b9a8;
  /* border-color: var(--bs-nav-tabs-link-active-border-color); */
}

button#uncontrolled-tab-example-tab-query {
  font-weight: 500;
}
button#uncontrolled-tab-example-tab-query:hover {
  color: #642315;
}
button#uncontrolled-tab-example-tab-tr {
  font-weight: 500;
}
button#uncontrolled-tab-example-tab-tr:hover {
  color: #642315;
}
