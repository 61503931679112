/* .nav-link{
    background-color: #E4CDAA;
    color: #642315;
} */
.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    /* color: var(--bs-nav-link-color); */
    color: #642315;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #642315;
    background-color: #E4CDAA;
    /* border-color: var(--bs-nav-tabs-link-active-border-color); */
}

#uncontrolled-tab-example{
    border-bottom: none;
    margin-bottom: 2rem;
    margin-top: 1rem;
    margin-left: 1.5rem; 
}