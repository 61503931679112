#addNew {
  margin-right: 1rem;
  width: 7rem;
  border: none;
  color: #642315;
  background-color: #e4cdaa;
  border-radius: 0.4rem;
}

#addNew:hover {
  background-color: #c9974b;
}

#filter {
  margin-right: 1rem;
  width: 7rem;
  border: none;
  color: #642315;
  background-color: #e4cdaa;
  border-radius: 0.4rem;
}

#filter:hover {
  background-color: #c9974b;
}
.statusBtn {
  background-color: #fa5f71;
  border: none;
  color: white;
  padding-left: 0.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  border-radius: 0.2rem;
}

.statusBtnComplete {
  background-color: #0fa958;
  border: none;
  color: white;
  padding: 0.3rem;
  border-radius: 0.2rem;
  width: 100px;
}
.pleaseChooseAnEmp {
  background-color: #e4cdaac6;
  border-radius: 7px;
  height: 30px;
  padding-left: 3px;
  padding-right: 5px;
  transition: all 0.5s;
  cursor: pointer;
  border: none !important;
  outline: none;
  margin-right: 10px;
}

.pleaseChooseAnEmp:focus {
  border: none !important; /* Ensure no border on focus */
  outline: none !important; /* Ensure no outline on focus */
}

.checkButton {
  width: 23px;
  margin-left: 7px;
}
.submit:hover{
  background-color:#d5a969 ;
}
.managedBy{
  display: flex;
  flex-direction: row;
  gap: 5px;
}


.fixed-width-select {
  width: 200px; /* Set your desired width here */
}

.selectitemsoforder{
  color: #642315;
  font-weight: 500;
  padding: 10rem;
}

#orderhistory1{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 12px;
}