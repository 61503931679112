.vendorInfo {
    border-radius: 0.8rem;
  border: none; /* Remove default hr border */
  /* height: 4px; Increase the height */
  background-color: #FEFEFE; /* Set hr color */
  margin: 20px auto; /* Center the hr */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

#nameRow{
    display: flex;
}

#badge{
    background-color: #E4CDAA;
    color: #642315;
    border-radius: 1rem;
}

.lItem{
    border-bottom: none;
}
.lItem span{
    font-weight: 500;
} 

#editVendor{
    cursor: pointer;
}

#deleteVendor{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {

    .lItem{
        border-bottom: none;
        padding: 0;
    }
}

