.amtopbar {
    background-color: #E4CDAA;
    font-family: "Roboto";
    padding: 0.5rem;
    font-size: large;
    color: #642315;
    margin-top: 3vh;
    border-radius: 9px;
    margin-top: 4vh;
}

.amsecondcol{
    text-align: end;
    font-family: "Roboto";
}
.secondrow{
    background-color: #F4F3F0;
    font-family: "Roboto";
    padding: 0.5rem;
    font-size: large;
    color: #642315;
    margin-top: 3vh;
    border-radius: 9px;
    border: 1px solid #595959;

}

.amthirdcol{
    background-color:  #F4F3F0;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
    border: 1px solid #595959;
}

.amproductdetails {
    font-family: "Roboto";
    color: #642315;
    font-size: larger;
    margin-top: 2vh;
    margin-bottom: 1.4rem;
}

  .amsecondcolomn{
    display: flex;
    gap: 3rem;
  }

  .amlastrow{
    
    margin-top: 2vh;
    margin-bottom: 2rem;
}

.glo-button-group {
    display: flex;
    justify-content: end;
    gap: 10px; 
  }
  .subadminform{
    background-color: #FEFEFE;
  }

  .subadminlastbutton{
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-right: 0.1rem;
    /* margin-bottom: 5rem; */
  }
  .selecttagsubadminform{
    background-color:  #F4F3F0;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
    width: 100%;
        border: 1px solid #595959;
        padding: 0.4rem;
        border-radius: 5px;
    
  }
  .updateaccount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  
  .eyeamthirdcol {
    padding-right: 2rem;
    background-color:  #F4F3F0;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
    border: 1px solid #595959;
  }
  
  .eyeicon {
    position: absolute;
    right: 0.5rem;
  }
  .updatelastbutton{
    display: flex;
    justify-content: start;
    gap: 1rem;
    margin-left: 0.1rem;
  }
  .updatesaleryform{
    margin-top: 1rem;
  }