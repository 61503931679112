
.saleryrow{
    margin-top: 1rem;
}


th, td {
    padding: 5px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #f5f5f5;
}

.action {
    text-align: center;
}

.action button {
    /* background-color: #f2f2f2; */
    border: none;
    color: #333;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
}

.lasttr{
    margin-bottom: 1rem;
}

#orderHistory{
    padding-top: 1rem;
    color: #642315;
    font-weight: 500;
    border-bottom: 1.5px solid #642315;
    margin-bottom: 1rem;
}