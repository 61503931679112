.sbm1 {
  height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
}
.sbm2 {
  height: 4rem;
}
.sbm3 {
  /* background: #ecf3ff; */
  background: #ffffff;
}
.sbm4 {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  /* padding-left: 0; */
  padding: 0.2rem 0 0.3rem 0;
  background: #f4f3f0;
}
.sbm5 {
  background: black;
  color: #ffffff;
  padding: 0.25rem;
  border-radius: 0.15rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.sbm6 {
  text-decoration: none;
  background: #e4cdaa;
  border-radius: 0.2rem;
  color: #dc2329;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 1rem;
  /* padding: 0.2rem 0.35rem 0.3rem 0; */
  padding: 0.2rem 0 0.3rem 0;
}
.sbm7 {
  /* height: 1.2em;
  width: 1.2em; */
  color: #ffffff;
}
.sbm8 {
  height: 0.65em;
  width: 0.65em;
  color: #642315;
}
.sbm9 {
  display: flex;
  align-items: center;
  color: black;
}
.sbm10 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.sbm11 {
  height: 0.9em;
  width: 0.9em;
}
.sbm12 {
  height: 1em;
  width: 1em;
}
.sbm13 {
  height: 100vh;
}
.sbm14 {
  max-height: calc(100vh - 7rem);
  overflow-y: auto;
  padding-left: 0.8rem;
  scrollbar-width: thin;
}
.sbm15 {
  padding: 0;
}
.sbm16 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sbm17 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sbm18 {
  height: auto;
  width: 80px;
  cursor: pointer;
}

a.sbm4.row {
  margin-top: 0.6rem;
  padding: 0.6rem;
  border-radius: 5px;
}
.dashboardTextActive {
  color: white;
}

.EmpActive {
  color: white;
}
.customerDataActive {
  color: white;
}
.OrderActive {
  color: white;
}
.itemActive {
  color: white;
}
.otherActive {
  color: white;
}
a.sbm6.row {
  margin-top: 0.6rem;
  padding: 0.6rem;
  border-radius: 6px;
  background-color: red;
}
.mmlogo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #642315;
  margin-left: -2rem;
  font-family: "Roboto";
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.panel-name {
  position: absolute;
  bottom: -1rem;
  right: 2px;
  background-color: #dc2329;
  color: white;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 5px;
  font-style: italic;
  font-weight: 600;
}
.jbsContained {
  color: black;
  font-size: 20px;
  margin-left: -10px;
}
