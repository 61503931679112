#productImgHead{
    color: #642315E5;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.ImageContainer{
    padding-left: 5.5rem;
    padding-right: 1.5rem;
}
.productImage{
    /* position: relative; */
    height: 150px;
    width: 150px;
    
}
#qrModalBody{
    font-weight: 400;
}
#deleteIcon{
    color: #642315;
    background-color: #E4CDAA;
    cursor: pointer;
}

.value{
    text-align: end;
}

.transactionHeading{
    color: #642315E5;
    font-weight: 500;
    border-bottom: 1.5px solid #642315E5;
    padding: 0.5rem;
    font-size: 18px;
}

.subadminlastbutton{
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-right: 0.1rem;
  }

  .leftButton{
    margin-top: 1rem;
    display: flex;
    justify-content: start;
    gap: 1rem;
    margin-left: 0.1rem;
  }

  .infoText{
    /* width: 100%; */
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
.QRinput{
    background-color: #F4F3F0;
}

#generateQR{
    border: none;
    color: #642315;
    background-color: #E4CDAA;
    padding: 5px;
    border: 1px solid #642315;
    border-radius: 0.3rem;
}
  @media only screen and (max-width: 600px) {
    .productImage{
        height: 300px;
        width: 300px;
    }
    .ImageContainer{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .m-1{
        margin: 0;
        margin-top: 1rem;
    }
  }