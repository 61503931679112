*{
  font-family: "Roboto";
}
.amtopbar {
    font-weight: 500;
    background-color: #E4CDAA;
    padding: 0.5rem;
    font-size: large;
    color: #642315;
    margin-top: 3vh;
    border-radius: 9px;
    margin-top: 4vh;
}
label{
  font-weight: 500;
}
.amsecondcol{
    text-align: end;
}

.amthirdcol{
    background-color:  #F4F3F0;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    border: 1px solid #595959;
}

.amproductdetails {
    color: #642315;
    font-size: large;
    margin-top: 2vh;
    margin-bottom: 0.4rem;
    font-weight: 400;
}

  .amsecondcolomn{
    display: flex;
    gap: 3rem;
  }

  .amlastrow{
    
    margin-top: 2vh;
    margin-bottom: 2rem;
}

.glo-button-group {
    display: flex;
    justify-content: end;
    gap: 10px; 
  }
  #transactionForm{
    background-color: #FEFEFE;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: "Roboto";
  }

  .subadminlastbutton{
    margin-top: 1rem;
    display: flex;
    justify-content: end;
    gap: 1rem;
    margin-right: 0.1rem;
  }
  .selecttagsubadminform{
    background-color:  #F4F3F0;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;
    width: 100%;
    border: 1px solid #595959;
    padding: 0.4rem;
    border-radius: 5px;
    
  }

  #formTransactionHead{
    font-size: 22px;
  }

  #formCross{
    cursor: pointer;
    font-size: 1.6rem;
  }

  .radioBtns{
    border: 1px solid #642315;
    padding: 0.5rem;
    border-radius: 0.3rem;
    background-color: #F4F3F0;
  }
  .secondrow{
    background-color: #F4F3F0;
    border-radius: 5px;
    border: 1px solid #595959;
    color: black;
    padding: 0.5rem;
}

.secondrowDiv{
  margin-left: 0.7rem;
  margin-right: 0.3rem;
  
}