#formProduct {
  font-weight: 500;
  color: #642315e5;
  font-size: 20px;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #642315e5;
}

.amthirdcol {
  background-color: #f4f3f0;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  border: 1px solid #595959;
}

.radioBtns {
  border: 1px solid #642315;
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: #f4f3f0;
}
