#deleteIcon {
  color: #642315;
  background-color: #e4cdaa;
  cursor: pointer;
}

.value {
  text-align: end;
}

.transactionHeading {
  color: black;
  font-weight: 500;
  border-bottom: 1.5px solid #642315e5;
  padding: 0.5rem;
}

#addNewIcon {
  margin-right: 1rem;
  width: 10rem;
  border: none;
  color: #642315;
  background-color: #e4cdaa;
  border-radius: 0.3rem;
}

.OrederdetailaMainModal {
  margin: 0 !important ;
  padding: 0 !important;
}
