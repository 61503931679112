#amtopbarsalery {
  background-color: #f4f3f0;
  font-family: "Roboto";
  padding: 0.5rem;
  font-size: large;
  color: #642315e5;
  margin-top: 3vh;
  font-weight: 500;
  border-radius: 9px;
  margin-top: 1vh;
}
/* color: "#642315E5", background: "#f4f3f0" */

.amsecondcol {
  text-align: end;
  font-family: "Roboto";
}
.secondrow {
  background-color: #f4f3f0;
  font-family: "Roboto";
  padding: 0.5rem;
  font-size: large;
  color: #642315;
  margin-top: 3vh;
  border-radius: 9px;
  border: 1px solid #595959;
}

.amthirdcol {
  background-color: #f4f3f0;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  border: 1px solid #595959;
}
.form-label {
  margin-bottom: 0rem !important;
}
.amproductdetails {
  font-family: "Roboto";
  color: black;
  font-weight: 500;
  font-size: larger;
  margin-top: 2vh;
  margin-bottom: 0.4rem;
}

.amlastrow {
  margin-top: 2vh;
  margin-bottom: 2rem;
}

.glo-button-group {
  display: flex;
  justify-content: end;
  gap: 10px;
}
.subadminform {
  background-color: #fefefe;
  padding: 1.5rem;
  border-radius: 14px;
  margin-top: 2rem;
}

.titleofcontainer {
  font-family: "Roboto";
  font-weight: 500;
}

.subadminlastbutton {
  /* margin-top: 1rem; */
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin-right: 0.1rem;
  /* margin-bottom: 5rem; */
}
.selecttagsubadminform {
  background-color: #f4f3f0;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  width: 100%;
  border: 1px solid #595959;
  padding: 0.4rem;
  border-radius: 5px;
}
.firstnames {
  font-family: "Roboto";
  font-weight: 500;
}
.admincrossicon {
  display: flex;
  margin-top: 0.3rem;
  justify-content: right;
}

.lastrowemp {
  margin: 0;
}
.uploadLoader {
  height: 10px;
  width: 10px;
}
.error {
  color: red;
  font-size: 13px;
}
.eyeIconnn {
  position: absolute;
  /* left: 550px; */
  top: 20%;
  /* transform: translateY(-50%); */
  right: 3%;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
}
.eyeIconnn svg {
  width: 1.4em;
  height: 1.4em;
}
