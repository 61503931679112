.dashcaard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
}
.dashrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.secondgraph {
  height: 400px; /* Adjust the height as per your requirement */
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 3vh;
  padding: 8px;
}

.dashboardmaincontainer {
  /* background-color: #fefefe; */
  margin-top: 1rem;
  height: 100%;
  padding: 1rem;
  border-radius: 16px;
}

.cardslice {
  /* padding: 1rem; */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.firstgraph {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-width: 320px;
  padding: 20px 10px 20px 0px;
  border-radius: 10px;
  background-color: #fefefe;
}
.css-1qdzy9k-MuiBarElement-root {
  fill: #642315 !important;
  /* width: 20px !important; */
}
#dropdown-basic {
  background-color: unset;
  border: none;
}
h5 {
  font-family: "Roboto";
  color: #642315;
}
h6 {
  color: #525252;
}
p {
  font: bold;
  font-size: larger;
}
.dashboardsecondrow {
  margin-top: 50px;
  display: flex;
  gap: 20px;
}
.alldatainfo.col {
  margin-top: 5px;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #552255; */
  height: 100px;
}

.hightalluser {
  /* padding-bottom: 3px; */
  line-height: 3rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.buttomline {
  width: 100%;
  height: 2px;
  color: #552255;
}
.alluserdataborder {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 10px;
  min-width: 250px;
  background-color: #fefefe;
}

.alluserdatabordertop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 0.3rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  place-items: center;
}
.alluserdatabordertop p {
  color: #642315;
  font-family: "Roboto";
  font-size: large;
  display: flex;
  align-items: center;
  padding: 2px;
  margin: 0 !important;
}
.graphgrid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f3f0;
  padding: 0.2rem;
  /* width: 88vh; */
  border-radius: 5px;
  margin-bottom: 2rem;
}
.graphsecondgrid {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.graphTitle {
  background-color: #f4f3f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 5px 3px 5px;
  border-radius: 10px;
  gap: 20px;
  margin-left: 12px;
}
.graphTitle div {
  background-color: #f4f3f0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}
.graphTitle > div > p {
  margin: 0 !important;
}

.dashboardchart {
  display: flex;
  justify-content: space-between;
}
.pichartfilter {
  border: none;
  border-radius: 3px;
  background-color: #f4f3f0;
  margin-top: 55%;
  outline: none;
}
.pichartfiltersmall {
  display: none;
}
@media (max-width: 500px) {
  .pichartfiltersmall {
    display: block;
  }
  .pichartfilter {
    display: none;
    margin-top: 0%;
  }
}
@media (max-width: 1024px) {
  .alluserdataborder {
    display: none;
  }
}
.queryCol {
  width: 70%;
}
.cardCol {
  width: 30%;
  min-width: 220px;
  padding: 0;
}
.lastRow {
  display: flex;
  gap: 15px;
  margin: 0;
  padding: 0;
}
.piChartAndBarGraph {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
}
.leaveEmp {
  height: 130px;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #fefefe;
}
.filterButton {
  min-width: 155px !important;
}
.addNewTask {
  min-width: 150px !important;
}
.secondRow {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.allTask {
  min-width: 150px !important;
}
.filterAndAddNewTask {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}
@media (min-width: 450px) {
  .filterAndAddNewTask {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
#allPendingOrder {
  height: 700px;
  overflow: auto;
}

.alluserdataborder::-webkit-scrollbar {
  width: 12px !important;
  height: 12px !important;
}
.alluserdataborder::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}
.alluserdataborder::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}
.alluserdataborder::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.TotalEmployee {
  color: #251c1a;
}

select.multiselect-dropdown.form-select {
  background-color: #dc2329;
  color: white;
}
