.privacycol {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Example box shadow */
    padding: 10px; /* Add some padding if needed */
    border-radius: 5px; /* Add border radius if needed */
    background-color: #fff; /* Ensure background color is set for better visibility */
  }
  

  .privacyeditbtn{
    display: flex;
    justify-content: right;
    margin-top: 0.6rem;
    cursor: pointer;
  }
  .privacycontained{
    padding: 0.3rem;
  }