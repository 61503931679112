/* Add these styles to your global CSS file */
.border-2 {
    border-width: 2px !important;
  }
  
  .border-dashed {
    border-style: dashed !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  .text-danger {
    color: #dc3545 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  