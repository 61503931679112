.second-box {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 1rem;
}

#btnnn {
  color: white;
  border: #e4cdaa;
  background-color: #dc2329;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-left: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
  float: right;
}
.spinnerrowitem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 350px;
}
.viewAndQRButton {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.qrCodeBackGround {
  height: 22px;
  width: 30px;
  /* background-color:#e4cdaa ; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}
/* .qrCodeBackGround:hover{
    background-color:#c9974b ;
  } */
.qrCode {
  cursor: pointer;
  height: 22px;
  width: 22px;
  color: #642315;
}
